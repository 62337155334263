import { Typography, Container } from '@mui/material';


let Homepage = () => {
    return (
        <Container maxWidth="sm">
            <Typography align="center" variant="h2">
                Welcome to Passione Beauty Business card
            </Typography>
        </Container>
    );
}

export default Homepage;