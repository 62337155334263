import { Navigate, Route, Routes as Router } from 'react-router-dom';
import { PublicRoute } from 'components';

import {
    BusinessCard,
    Error403,
    Error404,
    Homepage,
    Preview,
} from 'views';

let Routes = () => {
    return (
        <Router>
            <Route path="/" element={<PublicRoute children={<Homepage />} />} />

            <Route path="/:id" element={<PublicRoute children={<BusinessCard />} />} />
            <Route path="/preview/:id" element={<PublicRoute children={<Preview />} />} />

            <Route path="/error403" element={<PublicRoute children={<Error403 />} />} />
            <Route path="/error404" element={<PublicRoute children={<Error404 />} />} />

            <Route path="*" element={<Navigate to={"/error404"} />} />
        </Router>
    )
}

export default Routes;