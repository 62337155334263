import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = "#000000";
const rose = "#FF50AFB3";
const aquamarine = "#A5FFE6";
const yellow = "#FAFADC";
const iconBlack = "#133028";

export default {
  primary: {
    contrastText: white,
    dark: black,
    main: rose,
    light: rose
  },
  secondary: {
    contrastText: black,
    main: aquamarine,
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  icon: colors.blueGrey[600],
  iconBlack: iconBlack,
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200],
};
