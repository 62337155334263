import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';

interface IBusinessCardContext {
    businessCard: IBusinessCard
    isLoading: boolean

    getBusinessCard: () => void
    saveVCard: () => void
}


interface IBusinessCard {
    id: string
    firstName: string
    lastName: string
    jobTitle: string
    companyVatNumber: string
    department: string
    street: string
    postalCode: string
    city: string
    province: string
    country: string
    telephoneNumber: string
    mobile: string
    internalNumber: string
    email: string
    employeeCompanyName: string

}

export let businessCardContext = React.createContext<IBusinessCardContext>({} as IBusinessCardContext);

let { Provider } = businessCardContext;

let BusinessCardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { id } = useParams<{ id: string }>();

    let [isLoading, setLoading] = useState<boolean>(true);
    let [businessCard, setBusinessCard] = useState<IBusinessCard>({} as IBusinessCard);

    useEffect(() => {
        getBusinessCard();
    }, [id])

    let getBusinessCard = async () => {
        try {
            let res = await axios.get('/api/BusinessCard/GetBusinessCard', { params: { id } });

            let { item } = res.data;
            setBusinessCard({ ...item });

        } catch (err) { }
        finally {
            setLoading(false);
        }
    }

    let saveVCard = async () => {
        try {
            let res = await axios.get('/api/BusinessCard/CreateVCard', { params: { id: businessCard.id } });

            let filename = "file.vcf";
            let disposition = res.headers['content-disposition'];

            if (disposition && disposition.indexOf('attachment') !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1])
                    filename = matches[1].replace(/['"]/g, '');
            }


            if (navigator.canShare()) {

                await navigator.share({
                    title: 'New Contacts',
                    text: 'Save contacts',
                    files: [new File([res.data], 'newcontact.vcf', { type: 'text/vcard' })],
                });
            } else {

                let url = window.URL.createObjectURL(new Blob([res.data], { type: "text/vcard" }));
                // window.open("data:text/x-vcard;urlencoded," + url);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        } catch (err) { }
        finally {
            setLoading(false);
        }
    }

    return (
        <Provider value={{
            businessCard,
            isLoading,

            getBusinessCard,
            saveVCard
        }}>
            {children}
        </Provider>
    );
};

export default BusinessCardProvider;