import { CardMedia, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { previewContext } from './PreviewContext';
import palette from 'assets/palette';
import theme from 'assets';
import axios from 'axios';


let Preview = () => {
    let service = useContext(previewContext);
    let [qrCodeUrl, setQrCodeUrl] = useState('');

    useEffect(() => {
        if (service.isLoading === false)
            getEmployeeQrCode(false);

    }, [service.isLoading]);


    let getEmployeeQrCode = async (download: boolean) => {
        // if (service.preview.enableQRCode === false)
        //     return;

        try {

            let request = {
                employeeId: service.preview.id,
                download: download,
                backgroundTransparent: false
            }

            let res = await axios.get(`/api/QRCode/GetQrCode`, { params: request, responseType: 'blob' });

            let filename = "file.png";
            let disposition = res.headers['content-disposition'];

            if (disposition && disposition.indexOf('attachment') !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1])
                    filename = matches[1].replace(/['"]/g, '');
            }

            if (download === false) {
                let url = window.URL.createObjectURL(new Blob([res.data]));
                setQrCodeUrl(url);
            } else {
                let file = new Blob([res.data], { type: res.data.type });
                let url = window.URL.createObjectURL(file);
                let link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }

        } catch (err) { }
    }


    return (
        <Grid container style={{ display: 'flex' }}>
            {service.isLoading === false &&
                <>
                    <Grid item xs={12} style={{ padding: 0, textAlign: 'center' }}>
                        <img src='images/logo-black.png' style={{ width: '50%', paddingTop: theme.spacing(10), paddingBottom: theme.spacing(10) }} />
                    </Grid>
                    <Grid item xs={12} style={{ backgroundColor: service.preview.backgroundColor ?? palette.primary.main, maxHeight: 320 }}>
                        <Grid container textAlign='center' spacing={3} marginTop={theme.spacing(5)} marginBottom={theme.spacing(5)}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant='h2' sx={{ color: palette.primary.contrastText }}>
                                    {service.preview.firstName} {service.preview.lastName}
                                </Typography>
                                <Typography variant='h4' sx={{ color: palette.primary.contrastText }}>
                                    {service.preview.jobTitle}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs />
                            <Grid item xs marginTop={theme.spacing(1)}>
                                <CardMedia
                                    component="img"
                                    width={250}
                                    sx={{ margin: 'auto', width: 250, border: '1px solid #fafafa' }}
                                    src={qrCodeUrl !== '' ? qrCodeUrl : '/images/pb_placeholder.png'}
                                />
                            </Grid>
                            <Grid item xs />
                        </Grid>
                    </Grid>
                </>
            }
        </Grid>
    );
}

export default Preview;