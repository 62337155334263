import PreviewProvider from './PreviewContext';
import Preview from './Preview';
import { Container, useMediaQuery } from '@mui/material';
import theme from 'assets';

let Index = () => {
    const matches = useMediaQuery('(min-width:600px)');

    return (
        <PreviewProvider>
            <Container
                maxWidth={matches ? 'lg' : undefined}
                sx={{ paddingTop: theme.spacing(2), paddingLeft: 0, paddingRight: 0 }}
            >
                <Preview />
            </Container>
        </PreviewProvider>
    )
}

export default Index;