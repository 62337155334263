import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';

interface IPreviewContext {
    preview: IPreview
    isLoading: boolean

    getPreview: () => void
}


interface IPreview {
    id: string
    firstName: string
    lastName: string
    jobTitle: string
    companyVatNumber: string
    enableQRCode: boolean
    backgroundColor: string
}

export let previewContext = React.createContext<IPreviewContext>({} as IPreviewContext);

let { Provider } = previewContext;

let PreviewProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { id } = useParams<{ id: string }>();

    let [isLoading, setLoading] = useState<boolean>(true);
    let [preview, setPreview] = useState<IPreview>({} as IPreview);

    useEffect(() => {
        getPreview();
    }, [id])

    let getPreview = async () => {
        try {
            let res = await axios.get('/api/BusinessCard/GetPreview', { params: { id } });

            let { item } = res.data;
            setPreview({ ...item });

        } catch (err) { }
        finally {
            setLoading(false);
        }
    }


    return (
        <Provider value={{
            preview,
            isLoading,

            getPreview,
        }}>
            {children}
        </Provider>
    );
};

export default PreviewProvider;