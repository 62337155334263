import { BottomNavigation, BottomNavigationAction, Fab, Grid, Typography, collapseClasses } from '@mui/material';
import { useContext } from 'react';
import { businessCardContext } from './BusinessCardContext';
import palette from 'assets/palette';
import {
    Share as ShareIcon,
    PersonAdd as AddContactIcon,
    PhoneIphoneOutlined,
    AlternateEmailOutlined,
    RouteOutlined,
    WhatsApp,
    WorkOutlineOutlined,
    HomeWorkOutlined,
    ReceiptOutlined,
    FaxOutlined,
} from '@mui/icons-material';
import theme from 'assets';
import { BusinessCardInfo } from 'components';


let BusinessCard = () => {
    let service = useContext(businessCardContext);

    let openLink = (url: string) => {
        let link = document.createElement('a');
        link.href = url;

        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
    }

    let getAddress = () => {
        let address = '';

        if (service.businessCard.street)
            address = service.businessCard.street;

        if (service.businessCard.postalCode)
            address = address + ', ' + service.businessCard.postalCode

        if (service.businessCard.city)
            address = address + ' ' + service.businessCard.city

        if (service.businessCard.province)
            address = address + ' ' + service.businessCard.province

        //if (service.businessCard.country)
        //    address = address + ', ' + service.businessCard.country

        return address;
    }

    let getBusinessInfo = () => {
        let address = service.businessCard.employeeCompanyName;

        if (service.businessCard.street)
            address = address + '\n' + service.businessCard.street;

        if (service.businessCard.postalCode)
            address = address + '\n' + service.businessCard.postalCode

        if (service.businessCard.city)
            address = address + ' ' + service.businessCard.city

        if (service.businessCard.province)
            address = address + ' ' + service.businessCard.province

        if (service.businessCard.country)
            address = address + '\n' + service.businessCard.country

        return address;
    }

    return (
        <Grid container style={{ display: 'flex' }}>
            {service.isLoading === false &&
                <>
                    <Grid item xs={12} style={{ backgroundColor: 'black', padding: 0, textAlign: 'center' }}>
                        <img src='images/logo-white.png' style={{ width: '40%', paddingTop: 24, paddingBottom: 24 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container textAlign='center' spacing={3} marginTop={theme.spacing(1)} marginBottom={theme.spacing(2)}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant='h3' sx={{ color: palette.primary.main }}>
                                    {service.businessCard.firstName} {service.businessCard.lastName}
                                </Typography>
                                <Typography>
                                    {service.businessCard.jobTitle}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} marginTop={theme.spacing(1)}>
                                <BottomNavigation showLabels>
                                    <BottomNavigationAction
                                        label="Call"
                                        icon={<PhoneIphoneOutlined color='primary' style={{ fontSize: 50 }} />}
                                        onClick={(e) => openLink(service.businessCard.mobile
                                            ? `tel:${service.businessCard.mobile}`
                                            : `tel:${service.businessCard.telephoneNumber}`
                                        )}
                                    />
                                    <BottomNavigationAction
                                        label="Email"
                                        icon={<AlternateEmailOutlined color='primary' style={{ fontSize: 50 }} />}
                                        onClick={(e) => openLink(`mailto:${service.businessCard.email} `)}
                                    />
                                    <BottomNavigationAction
                                        label="Directions"
                                        icon={<RouteOutlined color='primary' style={{ fontSize: 50 }} />}
                                        onClick={(e) =>
                                            openLink(`https://www.google.com/maps/search/?api=1&query=${getAddress()}`)
                                        }
                                    />
                                </BottomNavigation>
                            </Grid>
                            <Grid item xs />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ backgroundColor: palette.background.default }}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <BusinessCardInfo
                                    value={service.businessCard.mobile}
                                    caption='Mobile'
                                    icon={<WhatsApp style={{ fontSize: 45 }} />}
                                    action={service.businessCard.mobile
                                        ? (e) => openLink(`tel:${service.businessCard.mobile}`)
                                        : undefined
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BusinessCardInfo
                                    value={service.businessCard.telephoneNumber}
                                    caption='Telephone'
                                    icon={<FaxOutlined style={{ fontSize: 45 }} />}
                                    action={service.businessCard.telephoneNumber
                                        ? (e) => openLink(`tel:${service.businessCard.telephoneNumber}`)
                                        : undefined
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BusinessCardInfo
                                    value={service.businessCard.email}
                                    caption='Email'
                                    icon={<AlternateEmailOutlined style={{ fontSize: 45 }} />}
                                    action={(e) => openLink(`mailto:${service.businessCard.email}`)}
                                />
                            </Grid>
                            {service.businessCard.department &&
                                <Grid item xs={12}>
                                    <BusinessCardInfo
                                        value={service.businessCard.department}
                                        caption='Division'
                                        icon={<WorkOutlineOutlined style={{ fontSize: 45 }} />}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <BusinessCardInfo
                                    value={getBusinessInfo()}
                                    caption='Company'
                                    icon={<HomeWorkOutlined style={{ fontSize: 45 }} />}
                                />
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} />
                            <Grid item xs={12} />
                        </Grid>
                    </Grid>
                    <Fab
                        sx={{
                            position: 'fixed',
                            bottom: theme.spacing(12),
                            right: theme.spacing(2)
                        }}
                        onClick={() => navigator.share({ url: `${process.env.PUBLIC_URL}/${service.businessCard.id}` })}
                    >
                        <ShareIcon fontSize='medium' />
                    </Fab>
                    <Fab
                        color='secondary'
                        sx={{
                            position: 'fixed',
                            bottom: theme.spacing(2),
                            right: theme.spacing(2)
                        }}
                        onClick={() => service.saveVCard()}
                    >
                        <AddContactIcon fontSize='medium' style={{ color: 'white' }} />
                    </Fab>
                </>
            }
        </Grid>
    );
}

export default BusinessCard;