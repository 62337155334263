import BusinessCardProvider from './BusinessCardContext';
import BusinessCard from './BusinessCard';
import { Container, useMediaQuery } from '@mui/material';
import theme from 'assets';

let Index = () => {
    const matches = useMediaQuery('(min-width:600px)');

    return (
        <BusinessCardProvider>
            <Container
                maxWidth={matches ? 'lg' : undefined}
                sx={{ paddingTop: theme.spacing(2), paddingLeft: 0, paddingRight: 0 }}
            >
                <BusinessCard />
            </Container>
        </BusinessCardProvider>
    )
}

export default Index;