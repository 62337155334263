import { Grid, Typography } from '@mui/material';
import react from 'react';

interface BusinessCardInfoProps {
    value: string
    icon: react.ReactNode
    caption?: string
    action?: (e: any) => void
}

let BusinessCardInfo = (props: BusinessCardInfoProps) => {

    return (
        <Grid container spacing={2} alignItems='center' onClick={props.action} sx={{ cursor: props.action ? 'pointer' : 'inherit' }}>
            <Grid item xs={3} sx={{ textAlign: 'center' }}>
                {props.icon}
            </Grid>
            <Grid item xs>
                <Typography style={{ whiteSpace: 'pre-line' }}>{props.value}</Typography>
                {props.caption &&
                    <Typography variant='caption'>{props.caption}</Typography>
                }
            </Grid>
        </Grid>
    );
}

export default BusinessCardInfo;